import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {format} from 'libphonenumber-js';

interface Person {
    name: string | undefined;
    image: string | undefined;
    jobTitle: string | undefined;
    address: string | undefined;
    email: string | undefined;
    phone: string;
    socialLinks: { icon: string; url: string }[];
}

function BusinessCard() {
    const {id} = useParams();

    const [person, setPerson] = useState<Person>();

    useEffect(() => {
        fetch('/api/users/' + id)
            .then((response) => response.json())
            .then((data) => {
                setPerson(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    if (!person) {
        return <div>Loading...</div>;
    }
    return (
        <div className="card">
            <img src={person.image} alt="Profile"/>
            <h1>{person.name}</h1>
            <h2>{person.jobTitle}</h2>
            <div className="social-links-box">
                <ul className="social-links">
                    {person.socialLinks.map((link, index) => (
                        <li key={index}>
                            <a href={link.url} target="_blank" rel="noopener noreferrer"
                               dangerouslySetInnerHTML={{__html: link.icon}}>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="contact-links-box">
                <ul className="contact-links">
                    <li>
                        <i className="fas fa-envelope"></i>
                        <a href={`mailto:${person.email}`}>{person.email}</a>
                    </li>
                    <li>
                        <i className="fas fa-phone"></i>
                        <a href={`tel:${person.phone}`}>{format(person.phone, 'INTERNATIONAL')}</a>
                    </li>
                    <li>
                        <i className="fas fa-map-marker-alt"></i>
                        <span>{person.address}</span>
                    </li>
                </ul>
            </div>
        </div>

    );
}

export default BusinessCard;
