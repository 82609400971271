import React from 'react';
import './App.css';
import BusinessCard from './BusinessCard';
import {Route, Routes} from 'react-router-dom';

function App() {
    return (
        <Routes>
            <Route path="/:id" element={<BusinessCard />}/>
        </Routes>
    );
}

export default App;
